<template>
  <div class="">

  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data () {
    return {

    }
  },
  methods: {
    authorize () {
      const token = this.$route.query.tk
      const path = this.$route.query.path
      if (!token) return
      // this.$store.commit('user/updateToken', { token })
      this.$store.dispatch('user/actionsGetUserInfo', token)

      const queryInfo = { ...this.$route.query }
      delete queryInfo.tk
      delete queryInfo.path
      this.$nextTick(() => {
        this.$router.replace({
          path: decodeURIComponent(path),
          query: queryInfo
        })
      })
    }
  },
  created () {
    this.authorize()
  }
}
</script>

<style lang="less" scoped></style>
